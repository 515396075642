
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Verfiy from './screens/Verification/Verfiy';
import PanVerify from './screens/Verification/PanVerify';
import Login from './screens/CoinsTransfer/Login';
import AadhaarVerify from './screens/Verification/AadhaarVerify';
import { useEffect, useState } from 'react';
import Transfer from './screens/CoinsTransfer/Transfer';
import Maintenance from './screens/Maintenance';
import AccountDelete from './AccountDelete';
import RummyLogin from './screens/Rummy-User-Campaign/RummyLogin';
import ContestScreen from './screens/Rummy-User-Campaign/ContestScreen';
import { Navigate } from 'react-router-dom';
import TicketScreen from './screens/NewOffer-UserPass/TicketScreen';
import UserPassLogin from './screens/NewOffer-UserPass/UserPassLogin';
import AllTickets from './screens/NewOffer-UserPass/AllTickets';
import CheckWinner from './screens/NewOffer-UserPass/CheckWinner';
import HistoryTickets from './screens/NewOffer-UserPass/HistoryTickets';
import Rules from './screens/NewOffer-UserPass/Rules';
import RummuRules from './screens/Rummy-User-Campaign/RummuRules';

function App() {
  const [mode, setMode] = useState(false)

  const [IsAuthenticated, setIsAuthenticated] = useState(false)
  useEffect(() => {

    // localStorage.clear();
    // return
  }, [mode])

  useEffect(() => {

    let JWT = localStorage.getItem('JWT');
    if (!JWT) {
      setIsAuthenticated(false)
    } else {
      setIsAuthenticated(true)
    }
    // localStorage.clear();
    // return
  },)

  function ProtectedRoute({ children }) {
    const JWT = localStorage.getItem('JWT');
    if (!JWT) {
      localStorage.clear()
      return <Navigate to="/rummy/login" replace />;
    }
    return children;
  }

  function ProtectedRouteUserPass({ children }) {
    const JWT = localStorage.getItem('JWT');
    if (!JWT) {
      localStorage.clear()
      return <Navigate to="/userpass/login" replace />;
    }
    return children;
  }

  return (
    <div className="App">
      <Router>
        <Routes>

          <Route path="/" element={<Verfiy />} />
          <Route path="/pan-verfiy" element={<PanVerify />} />
          <Route path="/login" element={mode ? <Maintenance /> : <Login />} />
          <Route path="/rummy/login" element={mode ? <Maintenance /> : IsAuthenticated ? <ContestScreen /> : <RummyLogin />} />
          <Route path="/rummy/campaign" element={mode ? <Maintenance /> : <ProtectedRoute><ContestScreen /></ProtectedRoute>} />
          <Route path="/rummy/rules" element={mode ? <Maintenance /> : <RummuRules />} />
          <Route path="/account/request/delete" element={mode ? <Maintenance /> : <AccountDelete />} />
          <Route path="/transfers" element={mode ? <Maintenance /> : <Transfer />} />
          <Route path="/aadhar-verfiy" element={<AadhaarVerify />} />

          <Route path="/userpass/login" element={mode ? <Maintenance /> : IsAuthenticated ? <TicketScreen /> : <UserPassLogin />} />
          <Route path="/userpass/tickets" element={mode ? <Maintenance /> : <ProtectedRouteUserPass><TicketScreen /></ProtectedRouteUserPass>} />
          <Route path="/userpass/Alltickets" element={mode ? <Maintenance /> : <ProtectedRouteUserPass><AllTickets /></ProtectedRouteUserPass>} />
          <Route path="/userpass/checkWinner" element={mode ? <Maintenance /> : <ProtectedRouteUserPass><CheckWinner /></ProtectedRouteUserPass>} />
          <Route path="/userpass/historyTickets" element={mode ? <Maintenance /> : <ProtectedRouteUserPass><HistoryTickets /></ProtectedRouteUserPass>} />
          <Route path="/userpass/rules" element={mode ? <Maintenance /> : <Rules /> } />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
