import React from 'react'
import "../../Verfiy.css"
import whatsappWeb from "./verfiyImage/whatsapp.webp"
function Queries() {
    return (
        <div className='queryBox'>
            <h3>Need Help : </h3>
            <div className='whatsappBox' onClick={() => window.open('https://api.whatsapp.com/send?phone=917428879142&text=Hello%20I%20have%20a%20problem.', '_blank')}>
                <span><img src={whatsappWeb} alt='whatsappIcon' /></span>
                <p>Whatsapp</p>
            </div>
        </div>
    )
}

export default Queries