import React, { useEffect, useState } from 'react'
import "../Rummy-User-Campaign/index.css"
import StarSvg from '../images/StarSvg'
import { Circle } from 'rc-progress';

import Star from "../images/winnerStarr.png"
import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Target } from "../images/target.svg"
import { ReactComponent as BigCross } from "../images/bigCross.svg"
import { ReactComponent as Achieved } from "../images/achieved.svg"
import { ReactComponent as Remianing } from "../images/remaining.svg"
import { ReactComponent as CompleteTick } from "../images/completeTick.svg"
import { ReactComponent as Cross } from "../images/cros.svg"
import { ReactComponent as CurrentStatuspic } from "../images/currentStatuspic.svg"
import { ReactComponent as RupeeIcon } from "../images/rupeeIcon.svg"
import { ReactComponent as Lock } from "../images/lock.svg"
import { ReactComponent as Logout } from "../images/logout.svg"
import loader from "../Verification/verfiyImage/khiladi_adda_loader.gif"
import { ReactComponent as Verified } from "../Verification/verfiyImage/verified.svg"
import { ReactComponent as Wrong } from "../Verification/verfiyImage/wrong.svg"
import { ReactComponent as Pending } from "../Verification/verfiyImage/pending.svg"
import { ReactComponent as RightTick } from "../images/rightTick.svg"
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function ContestScreen() {
    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }
    const handleDelete = () => {
        setOpen(false)
    }
    const [open, setOpen] = useState(false);
    const logoutopen = () => {
        setOpen(true)
        setDialog({
            icon: 0,
            message: 'Are you sure you want to logout?'
        })

    }
    const logoutcancel = () => {
        setOpen(false)
        setDialog({
            icon: 0,
            message: ''
        })
    }
    // function kFormatter(num) {
    //     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    // }

    function kFormatter(val) {

        if (val >= 10000000) val = (val / 10000000).toFixed(1) + ' Cr';
        else if (val >= 100000) val = (val / 100000).toFixed(1) + ' Lac';
        else if (val >= 1000) val = (val / 1000).toFixed(1) + ' K';
        val % 1 === 0 ? val.toString() : Number(val).toFixed(1)
        return val;
    }
    let tempobject = {
        "name": "2nd mile",
        "start_date": "2024-07-05T00:00:00.000Z",
        "end_date": "2024-07-05T23:59:59.999Z",
        "limit": 200,
        "prize_img": "https://khiladiadda-assets-misc.s3.ap-south-1.amazonaws.com/funny-website-error-messages_Thu-Jul-04-2024-16:09:00-4r6z6edrly74wfgn.gif",
        "is_prize": true,
        "_id": "66867bb2dec07a48cf3ce6ba",
        "invest_amount": 0
    }

    function getCurrentDate() {
        // Get current date in YYYY-MM-DD format
        let currentDate = new Date().toISOString().slice(0, 10);
        // console.log("currentDate", currentDate)
        return currentDate;
    }

    function findObjectsWithCurrentStartDate(data) {
        let currentDate = getCurrentDate();
        let matchedObjects = [];

        data.length > 0 && data.forEach((item, index) => {
            // Extract YYYY-MM-DD part of start_date
            let startDate = item.start_date.substring(0, 10);

            // Compare with current date
            if (startDate === currentDate) {
                // Push the object and its index into matchedObjects array
                matchedObjects.push({ index: index, object: item });
            }
        });

        return matchedObjects;
    }
    const navigate = useNavigate()

    const [milesTones, setmilesTones] = useState([])
    const [currentMilestone, setcurrentMilestone] = useState({})
    const [currentMilestoneDay, setcurrentMilestoneDay] = useState(0)

    const [Loading, setLoading] = useState(false)

    const [Task, setTask] = useState([])

    const logout = async () => {
        localStorage.clear();
        window.location.reload()
        navigate('/rummy/login')
    }
    useEffect(() => {
        fetchTasks()
    }, [])


    function checkAisPrizeActive(prize) {
        return prize.is_prize == true;
    }


    const fetchTasks = async () => {
        setLoading(true);
        let head = process.env.REACT_APP_ADMIN_URL || "https://apiv3.admin.khiladiadda.com/api/"
        let url = head + `admin/user-tasks-stats`
        let obj = {}
        obj['user_id'] = localStorage.getItem('_id')
        axiosClient
            .post(url, obj)
            .then((res) => {
                console.log(res.data)
                const data = res.data?.response;
                // console.log(data)
                setTask(data)
                setmilesTones(data?.milestones)

                let matchedObjects = findObjectsWithCurrentStartDate(data?.milestones);
                setcurrentMilestone(matchedObjects[0].object)
                setcurrentMilestoneDay(matchedObjects[0].index + 1)

                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
            });

    }

    function formatDate(inputDate) {
        let parts = inputDate.split("-");
        let formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
        return formattedDate;
    }

    if (Loading) {
        // return <h1 className='loading_text'>Loading.....</h1>
        return <div className='loaderKaBox'>
            <img src={loader} alt='loader' className='loaderKA' />
        </div>
    }


    return (
        <>
            <div className='r1_login'>
                <div className='r1_login_head'>
                    <StarSvg className='r1_login_star1' />
                    <StarSvg className='r1_login_star2' />
                    <StarSvg className='r1_login_star3' />
                    <StarSvg className='r1_login_star4' />
                    <h3 className='heading'>Rummy Weekly Offer</h3>
                    <p className='heading'>
                        Complete & Win
                    </p>
                </div>
                <div className='r1_daily_cover'>
                    <div className='big_days_circle'>
                        <div className='big_daycircle_inside'>
                            <Circle
                                percent={currentMilestone.invest_amount > currentMilestone.limit ? 100 : (currentMilestone.invest_amount / currentMilestone.limit) * 100}
                                strokeColor='white'
                                strokeWidth={5}
                                trailColor='red'
                                trailWidth={5}
                            />
                            <div className='circle_inside_text'>
                                <div className='limit_number'><RupeeIcon />{currentMilestone.limit ? kFormatter(Number(currentMilestone.limit).toFixed(2)) : 0}</div>
                                <div className='percentage_text'>{(currentMilestone.invest_amount || currentMilestone.limit) ? currentMilestone.invest_amount > currentMilestone.limit ? 100 : Math.ceil(currentMilestone.invest_amount / currentMilestone.limit * 100) : 0} % Completed</div>
                            </div>
                        </div>

                        {/* <div className='big_daycircle_inside'>
                          
                            <div>{currentMilestone.limit}</div>
                            <div className='percentage_text'>{(currentMilestone.invest_amount || currentMilestone.limit) ? (currentMilestone.invest_amount / currentMilestone.limit) * 100 : 0} % Completed</div>
                        </div> */}
                    </div>
                    <h5 className='dayName'> Day {currentMilestoneDay ? currentMilestoneDay : 0}</h5>
                </div>
                <div className='three_tier'>
                    <div className="tier_box">
                        <div className='flex_Area'>
                            <Target />
                            <h6>{currentMilestone.limit ? kFormatter(Number(currentMilestone.limit) % 1 === 0 ? kFormatter(currentMilestone.limit).toString() : kFormatter(currentMilestone.limit)) : 0}</h6>
                        </div>
                        <p>Todays Target</p>
                    </div>
                    <div className="tier_box">
                        <div className='flex_Area'>
                            <Achieved />
                            {/* <h6>{(currentMilestone.invest_amount || currentMilestone.limit) ? currentMilestone.invest_amount > currentMilestone.limit ? currentMilestone.limit : (currentMilestone.invest_amount).toFixed(2) : 0}</h6> */}
                            <h6>{(currentMilestone.invest_amount || currentMilestone.limit) ? (currentMilestone.invest_amount > currentMilestone.limit ? currentMilestone.limit.toString() : (currentMilestone.invest_amount % 1 === 0 ? kFormatter(currentMilestone.invest_amount).toString() : kFormatter(Number(currentMilestone.invest_amount) || 0).toFixed(2))) : 0}</h6>
                        </div>
                        <p>Achieved</p>
                    </div>
                    <div className="tier_box">
                        <div className='flex_Area'>
                            <Remianing />
                            <h6>{(currentMilestone.limit || currentMilestone.invest_amount) ? (currentMilestone.limit < currentMilestone.invest_amount ? 0 : ((currentMilestone.limit - currentMilestone.invest_amount) % 1 === 0 ? kFormatter(currentMilestone.limit - currentMilestone.invest_amount).toString() : kFormatter(Number(currentMilestone.limit) - Number(currentMilestone.invest_amount)))) : 0}</h6>
                        </div>
                        <p>Remaining</p>
                    </div>
                </div>
                {/* <div className='info_text'>Add 100000 to your wallet, to continue the challenge</div> */}
                {/* <div className='wallet_button'>Add to Wallet</div> */}

                <div className='remaining_days'>{currentMilestoneDay}/&nbsp;{milesTones?.length} Days</div>
                <div className='congo_text'>Well done! You’re getting closer to win exciting prizes</div>


                <div className='cicles_layout'>
                    {
                        milesTones?.length > 0 ? milesTones?.map((item, index) => {
                            let currentDate = item.start_date.substring(0, 10) == getCurrentDate()
                            let futureDate = item.start_date.substring(0, 10) > getCurrentDate()
                            let pastDate = item.start_date.substring(0, 10) < getCurrentDate()
                            return <div key={index} className='days_circle_outside'>
                                <div className='days_circle'>
                                    <div className='daycircle_inside'>
                                        <span>{index + 1}</span>
                                        <span>Day</span>
                                    </div>
                                    <div className='complete_tick'>
                                        {((item.invest_amount < item.limit) && pastDate) ? <Cross /> : item.invest_amount >= item.limit ? <CompleteTick /> : currentDate ? <CurrentStatuspic /> : <></>}
                                    </div>

                                </div>
                                <div className='amount_text'><RupeeIcon />{item.limit ? kFormatter(item.limit) : 0}</div>
                            </div>
                        }) : <></>
                    }
                </div>

                <div className='prize_background'>
                    <div className='outer_winner_box'>


                        <div className='inner_winner_box'>
                            <img src={Star} className='left_star' alt='leftStar' />
                            <span className='winner_text'>WINNING PRIZES</span>
                            <img src={Star} className='right_star' alt='rightStar' />
                        </div>                  </div>
                    <div className='containers'>
                        {
                            milesTones.length > 0 ? milesTones.filter(checkAisPrizeActive).map((item, index) => {
                                let currentDate = item.start_date.substring(0, 10) == getCurrentDate()
                                let futureDate = item.start_date.substring(0, 10) > getCurrentDate()
                                let pastDate = item.start_date.substring(0, 10) < getCurrentDate()
                                return <div style={{ display: (item.is_prize ? 'block' : 'none') }} key={index} className='poligon_box'>
                                    <div className='poligon'>
                                        <img src={item.prize_img} />
                                        {/* <div className='lock ss'>
                                            {((item.invest_amount < item.limit) && pastDate) ? <BigCross /> : item.invest_amount >= item.limit ? <RightTick /> : futureDate ? <Lock /> : <></>}
                                        </div> */}
                                    </div>
                                    <div className='pentagon_text'>
                                        <div>{item.name ? item.name : "Coffee Maker"}</div>
                                        <div>{formatDate(item.start_date.substring(0, 10))}</div>
                                    </div>
                                </div>
                            }) : <></>
                        }
                        <div className='lgoutBig'>


                            <div className='logoutButton' onClick={() => logoutopen()}>
                                <p>Logout</p>
                                <Logout />
                            </div>
                        </div>

                    </div>
                </div>

                <div className='stickBottom'>
                    <p onClick={() => navigate("/rummy/rules")}>How to Play?</p>                </div>
            </div>
            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>

                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='flexBody'>
                            <div className='okay' onClick={() => logoutcancel()}>No</div>
                            <div className='okay' onClick={() => logout()}>Yes</div>
                        </div>

                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default ContestScreen