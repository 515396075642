import React, { useEffect, useState , useRef , useCallback } from 'react'
import axiosClient from '../../axios';

import TicketComponent from './TicketComponent'
import loader from "../Verification/verfiyImage/khiladi_adda_loader.gif"
import "../NewOffer-UserPass/userpass.css"

function HistoryTickets() {

    const [HistoryTickets, setHistoryTickets] = useState([])
    const [Loading, setLoading] = useState(false)
    const jwt = JSON.parse(localStorage.getItem("JWT"));
    const _id = localStorage.getItem("_id");


    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();


    useEffect(() => {
        fetchHistoryTickets()
    }, [page])

    const fetchHistoryTickets = async () => {
        setLoading(true);
        let head = process.env.REACT_APP_ADMIN_URL || "https://apiv3.admin.khiladiadda.com/api/"
        let url = head + `admin/yesterday-user-passes`
        // let obj = {}
        // obj['user_id'] = localStorage.getItem('_id')

        try {
            const res = await new Promise(resolve => setTimeout(() => {
                axiosClient.post(url, { userId: _id, limit: 20, page: page }).then(resolve);
            }, 500)); // Introduce a 500ms delay

            const data = res.data?.response;
            setHistoryTickets(prevTickets => [...prevTickets, ...data]);
            setHasMore(data.length > 0); 
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }


        // axiosClient
        //     .post(url , { userId : _id , limit : 200})
        //     .then((res) => {
        //         const data = res.data?.response;
        //         setHistoryTickets(prevTickets => [...prevTickets, ...data])
        //         setHasMore(data.length > 0); 
        //         console.log(res.data)
        //         setLoading(false);
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     });

    }

    const lastTicketElementRef = useCallback(node => {
        if (Loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [Loading, hasMore]);

    if (Loading && page === 0) {
        // return <h1 className='loading_text'>Loading.....</h1>
        return <div className='loaderKaBox'>
            <img src={loader} alt='loader' className='loaderKA' />
        </div>
    }

    return (
        <>
            <div className='alltickets'>History</div>
            <div className='ticket_div' style={{height : '90vh'}}>
                <TicketComponent Tickets={HistoryTickets} lastTicketElementRef={lastTicketElementRef}  />
                {Loading && page > 0 && <div className="spinner">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>}
            </div>
        </>

    )
}

export default HistoryTickets