import React, { useEffect, useState } from 'react'
import "../Rummy-User-Campaign/index.css"
import "../NewOffer-UserPass/userpass.css"
import { Circle } from 'rc-progress';

import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import loader from "../Verification/verfiyImage/khiladi_adda_loader.gif"
import { ReactComponent as Dailycontestpic } from "../images/dailycontestpic.svg"
import { ReactComponent as Logout } from "../images/LogoutExit.svg"
import { ReactComponent as Wrong } from "../Verification/verfiyImage/wrong.svg"
import { ReactComponent as TicketLine } from "../images/ticketLine.svg"
import khiladiLogo from "../images/khiladiaddalogo.png"
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import TicketComponent from './TicketComponent';

SwiperCore.use([Autoplay]);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function TicketScreen() {
    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }
    const handleDelete = () => {
        setOpen(false)
    }
    const [open, setOpen] = useState(false);
    const logoutopen = () => {
        setOpen(true)
        setDialog({
            icon: 0,
            message: 'Are you sure you want to logout?'
        })

    }
    const logoutcancel = () => {
        setOpen(false)
        setDialog({
            icon: 0,
            message: ''
        })
    }
   

    // function getCurrentDate() {
    //     let currentDate = new Date();

    //     // Format the date to "18 July 2024"
    //     let options = { year: 'numeric', month: 'long', day: 'numeric' };
    //     let formattedDate = currentDate.toLocaleDateString('en-GB', options);
    //     return formattedDate;
    // }
    
    const navigate = useNavigate()

    const [Loading, setLoading] = useState(false)
    const [Tickets, setTickets] = useState([])
    const [count, setCount] = useState(0)

    const logout = async () => {
        localStorage.clear();
        window.location.reload()
        navigate('/userpass/login')
    }
    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
        fetchTasks()
    }, [])


    const jwt = JSON.parse(localStorage.getItem("JWT"));
    const _id = localStorage.getItem("_id");

    // function WonFalse(tickets) {
    //     const filtertickets = tickets.filter((item) => {
    //             return item.is_won == false
    //     })
    //     return filtertickets
    // }


    const fetchTasks = async () => {
        setLoading(true);
        let head = process.env.REACT_APP_ADMIN_URL || "https://apiv3.admin.khiladiadda.com/api/"
        let url = head + `admin/user-actived-passes`
        // let obj = {}
        // obj['user_id'] = localStorage.getItem('_id')
        axiosClient
            .post(url, { userId : _id , limit : 5 })
            .then((res) => {
                const data = res.data?.response;
                const count = res.data?.count;
                setCount(count)
                setTickets(data)
                console.log(res.data)
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
            });

    }

    // function formatDate(inputDate) {
    //     let parts = inputDate.split("-");
    //     let formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    //     return formattedDate;
    // }

    if (Loading) {
        // return <h1 className='loading_text'>Loading.....</h1>
        return <div className='loaderKaBox'>
            <img src={loader} alt='loader' className='loaderKA' />
        </div>
    }


    // const banners = [
    //     {
    //         _id: 1,
    //         image: "https://khiladiadda-assets-misc.s3.ap-south-1.amazonaws.com/banner2_Fri-Aug-09-2024-14:45:21-fyyjf4klzmhrj10.png"
    //     },
    //     {
    //         _id: 0,
    //         image: "https://khiladiadda-assets-misc.s3.ap-south-1.amazonaws.com/banner1_Fri-Aug-09-2024-14:44:31-fyyjf4klzmhqgra.png"
    //     },
    //     // {
    //     //     _id : 2,
    //     //     image: 'https://d25m2niryyb68h.cloudfront.net/images_1708524806094-2sgziz34yclsvjrnrj.jpeg'
    //     // }
    // ]

    const banners = [
        {
            _id: 0,
            image: "https://khiladiadda-assets-misc.s3.ap-south-1.amazonaws.com/KL-Daily_Mon-Aug-12-2024-14:15:38-fyyjf4klzqr0vbo.png"
        },
        {
            _id: 1,
            image: "https://khiladiadda-assets-misc.s3.ap-south-1.amazonaws.com/KL-Daily-contest-Banneree_Mon-Aug-12-2024-13:14:32-fyyjf4klzqouakt.jpg"
        },
        // {
        //     _id : 2,
        //     image: 'https://d25m2niryyb68h.cloudfront.net/images_1708524806094-2sgziz34yclsvjrnrj.jpeg'
        // }
    ]


    return (
        <>
            <div>
                <div className='contestpic'>
                    <Dailycontestpic/>
                </div>
            </div>

            <div className='swiper_layout'>
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#FF9933",
                        "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "10px",
                        // "--swiper-pagination-bullet-horizontal-gap": "6px"
                    }}
                    className='leaderBoard_slider_swiper'
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[Pagination]}
                    spaceBetween={50}
                    slidesPerView={1}
                    scrollbar={{ draggable: true }}
                    loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                >
                    {banners?.length > 0 && banners?.map((ele, index) => {
                        return (
                                <SwiperSlide key={ele._id}>
                                    <div className='leader_image' onClick={() => { index == 1 && navigate('/userpass/rules')}}>
                                        <img src={ele?.image} alt='refer' />
                                    </div>
                                </SwiperSlide>
                        )
                    })}
                </Swiper>


            </div>
          
            <div className='ticket_heading'>
                <div>Your Tickets</div>
                <div>Count : {count ? count : 0}</div>
            </div>
            
            <div className='ticketLine'><TicketLine /></div>

            <div className='Bigcheck_winner'>
            <div onClick={() => navigate('/userpass/historyTickets')} className='check_winner' style={{background:'linear-gradient(175.31deg, #e7bf5e -5.75%, #c99d36 80.33%)'}}>
                    <p>History</p>
                </div>
                <div onClick={() => navigate('/userpass/checkWinner')} className='check_winner'>
                    <p>Winner</p>
                </div>
            </div>

            <div className={ Tickets.length == 0 ? 'ticket_div zeroTicket' : 'ticket_div'}>
                {/* {Tickets.length > 0 ? Tickets.map((item , index) => {
                    return <div key={index} className='ticket_layout'>
                        <div className='ticket'>
                            <div className='left_ticket'>
                                <span></span>
                            </div>
                            <div className='right_ticket'>
                                <div className='dotted_border'>
                                    <div>
                                        <div className='tick_number'>{item.pass_id ? item.pass_id : '356789098765'}</div>
                                        <p>Ticket Number</p>
                                    </div>
                                    <div>Valid until {getCurrentDate()} 23:59 PM</div>
                                    <div className='ticket_logo'>
                                        <img src={khiladiLogo} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                }) : <div className='no_tickets'>
                    No Tickets
                </div>} */}
                <TicketComponent Tickets={Tickets.slice(0,2)} />
                <div className='bottom_buttons'>
                    {Tickets.length > 2 && <div onClick={() => { navigate('/userpass/Alltickets') }} className='seemore_button'>See more</div>}
                    <div style={{ margin: '0px' }} className='logoutButton'  onClick={() => logoutopen()}>
                        <p>Logout</p>
                        <Logout />
                    </div>
                </div>
            </div>
         
          
            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>

                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='flexBody'>
                            <div className='okay' onClick={() => logoutcancel()}>No</div>
                            <div className='okay' onClick={() => logout()}>Yes</div>
                        </div>

                    </div>
                </div>
            </Dialog >

        </>
    )
}

export default TicketScreen