import React, { useEffect } from 'react'
import "../Rummy-User-Campaign/index.css"
import Klogo from "../images/khiladiaddalogo.png"
import StarSvg from '../images/StarSvg'

function RummuRules() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <div className='r1_login'>
                <div className={`r1_login_logo`}>
                    <img src={Klogo} className='r1_khiladiLogo' alt='khiladiLogo' />
                </div>
                <div className='r1_login_head'>
                    <StarSvg className='r1_login_star1' />
                    <StarSvg className='r1_login_star2' />
                    <StarSvg className='r1_login_star3' />
                    <StarSvg className='r1_login_star4' />
                    <h3 className='heading'>Rummy Weekly Offers</h3>
                    <p className='heading'>
                        Rules & Regulations
                    </p>


                </div>
                <div className='rules_text'>
                    <h4>Target Amount</h4>
                    <p>
                        The platform will set a daily Rummy play target (e.g., ₹1 lakh).</p>
                    <p>The target amount must be clearly communicated to the players before the challenge starts.</p>
                    <br />
                    <h4>Challenge Duration:</h4>

                    <p>The challenge will run for a specified number of days (e.g., 7 days).</p>
                    <p>The exact duration should be clearly communicated to the players before the challenge starts.</p>
                    <br />
                    <h4>Participation:</h4>

                    <p>Any registered user can participate in the challenge.</p>
                    <p>All rummy players are already a participate, you just have to track your progress. By clicking on banner and get OTP on your registered mobile number on Khiladi Adda.</p>
                    <br /> <h4>Daily Target Achievement:</h4>

                    <p>To qualify for the prize, players must achieve the daily Rummy play target for every day of the challenge.</p>
                    <p>The daily target must be met by the end of each day (according to platform's time zone).</p>
                    <br /><h4>Prize Eligibility:</h4>

                    <p>Players who successfully achieve the daily target for all challenge days will be eligible for the prize.</p>
                    <p>If a player fails to meet the daily target on any single day, they will be disqualified from winning the prize.</p>
                    <br /><h4>Prize:</h4>

                    <p>The prize will be clearly communicated to the players before the challenge starts.</p>
                    <p>The prize can be cash, bonus points, Physical gifts or other incentives.</p>
                    <br /><h4>Tie-breakers:</h4>

                    <p>This offer could not get tie as its not a competition, its personalize Tasks. Available for all khiladi adda users</p>
                    <br /><h4>Fair Play:</h4>

                    <p>The platform reserves the right to disqualify any player found to be engaging in fraudulent or unfair play activities.</p>
                    <br /><h4>Offer Modification:</h4>

                    <p>The platform reserves the right to modify or cancel the offer at any time.</p>
                </div>
            </div>
        </>

    )
}

export default RummuRules