import React from 'react'
import loader from "./images/web-maintenance.png"

function Maintenance() {
    return (
        <div className='verfiyMain'>
            <div className='verfiyTop main'>
                <h1>Maintenance Mode</h1>
            </div>
            <div className='maintenacneMode'>
                <div className='homeUImage'>
                    <img src={loader} alt="homeImage" />
                </div>
                <h4>Hello Khiladi</h4>
                <p>To Bring more better experience and offer for you, maintainance of each part is compulsory.
                    Transfer Coins Cashback Offer will restart after few hours. Stay Tune!!! <br /><br /> Please Close the browser and Play Khiladi Adda without any issue!!!</p>
                <p>Aap sabhi ko aur behtar experience aur offers dene ke liye, sabhi cheezo ka ache se kaam karna bohot zaroori hai.
                    Transfer Coins cashback offer kuch hi ghanto me firse start hoga. Check karte rahe. <br /><br /> Abhi ke liye, Browser back karke. Khiladi adda pe game bina kisi pareshani ke khele.</p>
            </div>
        </div>
    )
}

export default Maintenance