import React, { useEffect, useState } from 'react'
import "../Rummy-User-Campaign/index.css"
import Klogo from "../images/khiladiaddalogo.png"
import StarSvg from '../images/StarSvg';
import OTPInput from 'react-otp-input';
import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Verified } from "../Verification/verfiyImage/verified.svg"
import { ReactComponent as Wrong } from "../Verification/verfiyImage/wrong.svg"
import { ReactComponent as Pending } from "../Verification/verfiyImage/pending.svg"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function RummyLogin() {
    const l_otp = JSON.parse(localStorage.getItem("loginOtp"));
    const l_Number = JSON.parse(localStorage.getItem("loginNumber"));
    const l_id = JSON.parse(localStorage.getItem("JWT"));
    const [number, setNumber] = useState(l_Number?.number || '');
    const [otp, setOtp] = useState('');


    const [otpBox, setOtpBox] = useState(false);
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);
    const [isOtpSent, setIsOtpSent] = useState(l_otp?.otp || false);

    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }
    const handleDelete = () => {
        setOpen(false)
    }

    const sendOtp = async () => {
        // setOtpBox(true)
        setLoading(true);
        if (!number || number.length === 0 || Number(number.slice(0, 1)) < Number(6)) {
            setLoading(false);
            handleOpen(0, "Enter your Mobile Number")
        }
        else if (number.length < 10 || !number.length) {
            setLoading(false);
            handleOpen(0, "Enter your 10 digit Mobile Number")
        }
        else if (number.length === 10) {
            const data = {
                mobile: number
            }
            axiosClient
                .post(`/auth/login`, data)
                .then((res) => {
                    localStorage.setItem("loginOtp", JSON.stringify({ otp: true }));
                    localStorage.setItem("loginNumber", JSON.stringify({ number: number }));
                    setIsOtpSent(true);

                    setLoading(false);
                    if (res.data.status == false) {
                        handleOpen(0, res.data.message)
                    }
                    else if (res.data.status === true) {
                        setOtpBox(true);
                        handleOpen(1, "OTP has been successfully send to your number.")
                    }
                })
                .catch((err) => setLoading(false));
        }
        else {
            setLoading(false);
            handleOpen(0, "Please fill correct number.")
        }

    }
    const changeNumber = () => {
        setNumber('')
        localStorage.removeItem("loginNumber");
        setOtpBox(false)
        setSeconds(30)
        setMinutes(0)
        setOtp('')
        setIsOtpSent(false)
    }
    const submitOtp = async () => {
        setLoading(true);
        if (otp?.length < 6) {
            setLoading(false);
            setOpen(true)
            setDialog({
                icon: 0,
                message: `Please Enter 6 Digit OTP.`
            })
            return
        }
        if (otp?.length === 6) {
            const data = { mobile: number, otp: otp }
            await axiosClient.post(`/auth/website/verify_password_otp/login`, data)
                .then((res) => {
                    setLoading(false);
                    if (res?.data?.status === true) {
                        localStorage.setItem("loginOtp", JSON.stringify({ otp: false }));
                        // setJwts(res.data.jwt)
                        try {
                            handleOpen(0, "SuccessFully Login")

                            axiosClient.get("/profile?transaction=false", {
                                headers: {
                                    'x-access-token': res.data.jwt,

                                }
                            }).then((respon) => {
                                if (respon.data.status == true) {
                                    handleOpen(1, "Otp has been verified.")
                                    localStorage.setItem("MOBILE", JSON.stringify(number))
                                    localStorage.setItem("JWT", JSON.stringify(res.data.jwt))
                                    localStorage.setItem("_id", respon.data.response._id)
                                    navigate("/rummy/campaign")
                                }
                            })
                        } catch (error) {
                            console.log("error", error)
                            handleOpen(0, "Some Technicnal Issue")
                        }

                    }
                    else {
                        // setIncorrectCount(incorrectCount + 1)
                        handleOpen(0, res.data.message)
                    }
                })
                .catch((err) => {
                    // setIncorrectCount(incorrectCount + 1)
                    setLoading(false);
                    handleOpen(0, "Otp Verification Failed.Please try again later")
                });
        }

    }
    const resendOtp = async () => {
        setLoading(true);
        setMinutes(0);
        setSeconds(30);
        const data = {
            mobile: number
        }
        handleOpen(2, `Sending Otp Again`)

        await axiosClient
            .post(`/auth/login`, data)
            .then((res) => {
                setLoading(false)
                setIsOtpSent(true);

                if (res.data.status === false) {
                    // handleOpen(0, res.data.message)
                }
                else if (res.data.status === true) {
                    setOtpBox(true);
                    // handleOpen(1, res.data.message)
                }
            })
            .catch((err) => setLoading(false));

    }
    useEffect(() => {
        if (l_id) {
            navigate("/rummy/campaign")
            return
        }
        if (otpBox || isOtpSent) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }

    }, [otpBox, seconds, minutes, isOtpSent]);

    return (
        <>
            <div className='r1_login'>
                <div className={`r1_login_logo ${otpBox && 'smallLogo'}`}>
                    <img src={Klogo} className='r1_khiladiLogo' alt='khiladiLogo' />
                </div>
                <div className='r1_login_head'>
                    <StarSvg className='r1_login_star1' />
                    <StarSvg className='r1_login_star2' />
                    <StarSvg className='r1_login_star3' />
                    <StarSvg className='r1_login_star4' />
                    <h3 className='heading'>Rummy Weekly Offers</h3>
                    <p className='heading'>
                        Complete & Win
                    </p>
                </div>
                {
                    !otpBox && !isOtpSent
                        ?
                        <div className='r1_number_box'>
                            <h4 className='r1_login_head2'>Login</h4>
                            {/* <p className='r1_login_para2'>Lorem ispum dolar slit lorem ispum dolar slit </p> */}
                            <div className='r1_login_input'>
                                <input
                                    id="name"
                                    type="number"
                                    className="r1_login_input_box"
                                    value={number}
                                    placeholder={"Enter your Mobile Number"}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10) {
                                            setNumber(e.target.value)
                                        }
                                    }}
                                />
                                <p className='r1_get_otp' onClick={() => sendOtp()}>Get OTP</p>

                            </div>

                        </div> :
                        <div className='r1_login_otp_input'>
                            <h4 className='r1_login_head2'>Verify With OTP</h4>
                            <div className='spaceBetween'>
                                <p className='r1_login_para2 para3'>Sent via SMS to +91 {number}</p>
                                <p className='r1_login_para4' onClick={() => changeNumber()}>Change Number</p>
                            </div>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                inputType="number"
                                inputStyle="otpInput"
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props}
                                />
                                }
                            />
                            <div className='spaceBetween mt10'>
                                <p className='r1_login_para2 para3'>Wait
                                    &nbsp;{minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}&nbsp;
                                    to Resend </p>
                                <p
                                    className={seconds > 0 || minutes > 0 ? 'r1_login_para4 light' : 'r1_login_para4 light active'}
                                    onClick={() => {
                                        if (seconds > 0 || minutes > 0) {
                                            return
                                        } else {
                                            resendOtp();
                                        }
                                    }}
                                >Send Again</p>
                            </div>
                            <p className='r1_get_otp' onClick={() => submitOtp()}>Submit</p>

                        </div>}
                <div className='stickBottom'>
                    <p onClick={() => navigate("/rummy/rules")}>How to Play?</p>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>

                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='okay' onClick={() => handleDelete()}>Okay</div>
                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default RummyLogin