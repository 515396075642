import React, { useEffect } from 'react'
import { useState } from 'react'
import "../../Verfiy.css"
import axios from "axios";
import homeImage from "./verfiyImage/verification.png"
import loader from "./verfiyImage/khiladi_adda_loader.gif"

import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import { ReactComponent as Verified } from "./verfiyImage/verified.svg"
import { ReactComponent as Wrong } from "./verfiyImage/wrong.svg"
import { ReactComponent as Pending } from "./verfiyImage/pending.svg"
import { useNavigate } from 'react-router-dom'
import { SHA256 } from 'crypto-js';
import Queries from './Queries';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function PanVerify() {
    const [pan, setPan] = useState('');
    const [open, setOpen] = useState(false);
    const [otpBox, setOtpBox] = useState(false)
    const [loading, setLoading] = useState(false)
    const [is_pan, setIs_pan] = useState(false);
    const userAadhar = JSON.parse(localStorage.getItem("AADHAR_OTP_DETAILS"));
    const mobile = JSON.parse(localStorage.getItem("MOBILE"));
    const [name, setName] = useState(userAadhar?.name?.toUpperCase());

    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const handleDelete = () => {
        setOpen(false)
    }

    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }
    function encodeBase64(data) {
        return btoa(JSON.stringify(data));
    }
    const navigate = useNavigate();
    // user login otp
    const submitOtp = async () => {
        if (pan?.length < 10 || !pan?.length || !name?.length || !name) {
            handleOpen(0, "Please fill correct PAN number")
        }
        if (pan.length === 10 && name) {
            setOtpBox(true)
            setLoading(true)
            let panObject = {
                "id_number": pan
            }
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYyOTcwMzcyNCwianRpIjoiZjM3NDlmZjEtZjY0ZS00MTkyLTg0OGQtZjY2MjEzZTIwYTM3IiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LmtoaWxhZGlhZGRhQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE2Mjk3MDM3MjQsImV4cCI6MTk0NTA2MzcyNCwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInJlYWQiXX19.DCYLErPGBaMa7mZQdaE1NgcEJSy6e-qIU9bXp9wQ6zc',
                    'Content-Type': 'application/json'
                }
            };
            let uri = 'https://kyc-api.aadhaarkyc.io/api/v1/pan/pan';

            try {



                let responseBody = await axios.post(uri, panObject, axiosConfig);
                let objectData = responseBody?.data
                if (objectData.status_code === 200 && objectData.success === true) {
                    let breakName = objectData.data.full_name.split(" ")
                    if (name.includes(breakName[0]) && objectData.data.pan_number === pan) {
                        let data = {
                            userAadhar,
                            pan: {
                                name: objectData?.data?.full_name,
                                panNumber: objectData?.data?.pan_number
                            },
                            mobile

                        }
                        await axiosClient
                            .post("/user/mobile/uan/exists", {
                                number: objectData.data.pan_number
                            })
                            .then((respon) => {
                                if (respon.data.status === false) {
                                    setLoading(false);
                                    setPan('')
                                    handleOpen(1, "PAN already taken. Please try another PAN Card")
                                } else if (respon.data.status === true) {
                                    setLoading(false);
                                    const base64Data = encodeBase64(data);
                                    let hash = SHA256(base64Data + process.env.REACT_APP_PAN_SHA_KEY).toString();
                                    hash += "###" + 27;
                                    data.hash = hash
                                    axiosClient
                                        .post(`user/mobile/kyc_info `, data)
                                        .then((res) => {
                                            if (res.data.status === false) {
                                                handleOpen(0, res.data.message)
                                            }
                                            else if (res.data.status === true) {
                                                setIs_pan(true)
                                                setOtpBox(true);
                                                handleOpen(1, `Your KYC has been completed.\n Our team will contact you within 24 hours via phone or WhatsApp to guide you through the TDS certification process.`)
                                            }
                                        })
                                        .catch((err) => {

                                            handleOpen(0, "Some technical Issue. Try again later.")
                                            setOtpBox(false)
                                        });
                                }
                            })


                    } else {
                        console.log("err")
                        setPan('')
                        setLoading(false)
                        handleOpen(0, "PAN Verfication Failed. Please enter correct details")
                    }
                } else {
                    setLoading(false)
                    setPan('')
                    handleOpen(0, "PAN Verfication Failed. Please enter correct details")
                }
            } catch (err) {
                setLoading(false)
                setPan('')
                handleOpen(0, "PAN Verfication Failed. Please enter correct details")
            }
        }

    }



    const navigateToHome = () => {
        localStorage.clear();
        navigate("/")
    }
    useEffect(() => {
        const preventPullToRefresh = (event) => {
            if (event.touches.length > 1) return;
            event.preventDefault();
        }
        document.addEventListener('touchmove', preventPullToRefresh, { passive: false });
        return () => {
            document.removeEventListener('touchmove', preventPullToRefresh);
        };
    }, []);
    useEffect(() => {
        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
    }, [])

    return (
        <>
            <div className='verfiyMain'>
                <div className='verfiyTop'>
                    <h1>Complete Your PAN KYC</h1>
                </div>
                {loading ?
                    <div className='loaderKaBox'>
                        <img src={loader} alt='loader' className='loaderKA' />
                    </div>
                    : <>
                        <div className='verifyBody'>
                            <div className='homeUImage'>
                                <img src={homeImage} alt="homeImage" />
                            </div>
                            {
                                is_pan ?
                                    <>
                                        <div className='aadhaarDetails'>
                                            <h3>Aadhaar Details</h3>
                                            <div className='basicDetail'>
                                                <p><span>Name : </span> {userAadhar?.name}</p>
                                                <p><span>DOB : </span> {userAadhar?.dob}</p>
                                                <p><span>City : </span> {userAadhar?.city}</p>
                                                <p><span>State : </span> {userAadhar?.state}</p>
                                                <p><span>Country : </span> {userAadhar?.country}</p>
                                                <p><span>Zip Code : </span> {userAadhar?.zip_code}</p>
                                                <p><span>Mobile Number : </span> {mobile}</p>
                                            </div>
                                            <h3>PAN Details</h3>
                                            <div className='basicDetail'>
                                                <p><span>Name : </span> {userAadhar?.name}</p>
                                                <p><span>PAN : </span> {pan}</p>
                                            </div>
                                            <div className='verfiysubmitCover'>
                                                <p className='verifysubmit' onClick={() => navigateToHome()}>Okay</p>

                                                <p className='suBtext'>Your KYC has been completed. Our team will contact you within 24 hours via phone or WhatsApp to guide you through the TDS certification process.</p>
                                                <p className='suBtext'>आपका KYC पूरा हो गया है। हमारी टीम आपको 24 घंटे के भीतर फोन या व्हाट्सएप के जरिए संपर्क करेगी और आपको टीडीएस सर्टिफिकेशन प्रक्रिया के बारे में सहायता प्रदान करेगी।।</p>
                                            </div>
                                        </div>

                                    </> :
                                    <>
                                        <div className='inputDetails'>

                                            <p className='para'>Name (same as on AADHAR Card)</p>

                                            <div className='inputCover mb20'>
                                                <input
                                                    id="name"
                                                    type="text"
                                                    disabled
                                                    className="profileInput"
                                                    value={name}
                                                />

                                            </div>

                                            <p className='para'>PAN Number</p>

                                            <div className='inputCover'>
                                                <input
                                                    id="pan"
                                                    type="text"
                                                    className="profileInput"
                                                    value={pan}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 10) {
                                                            setPan(e.target.value.toUpperCase())
                                                        }
                                                    }}
                                                />

                                            </div>
                                            <div className='verfiysubmitCover'>
                                                <h3 className='verifysubmit' onClick={() => submitOtp()}>Submit</h3>
                                            </div>
                                        </div>
                                    </>
                            }
                            <Queries />

                        </div>
                    </>}
            </div>

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>
                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='okay' onClick={() => handleDelete()}>Okay</div>
                    </div>
                </div>
            </Dialog >

        </>
    )
}

export default PanVerify












