import React from 'react'
import khiladiLogo from "../images/khiladiaddalogo.png"
import "../Rummy-User-Campaign/index.css"
import "../NewOffer-UserPass/userpass.css"

function TicketComponent({Tickets , lastTicketElementRef}) {

    let userID = localStorage.getItem('_id')

    // function getCurrentDate(date) {
    //     let currentDate = new Date(date);

    //     // Format the date to "18 July 2024"
    //     let options = { year: 'numeric', month: 'long', day: 'numeric' };
    //     let formattedDate = currentDate.toLocaleDateString('en-GB', options);
    //     return formattedDate;
    // }

    function formatDate(dateStr) {
        const date = new Date(dateStr);
      
        const day = date.getUTCDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getUTCFullYear();
      
        return `${day} ${month} ${year}`;
      }

    return (
            <>
                {Tickets && Tickets.length > 0 ? Tickets.map((item, index) => {
                    let winnerCoupon = item.user_id == userID;
                    const isLastTicket = Tickets.length === index + 1;
                    // Attach the ref to the last ticket element
                    return <div
                        ref={isLastTicket ? lastTicketElementRef : null}
                        key={index} className='ticket_layout'>
                        <div className='ticket'>
                            <div className='left_ticket'>
                                <span></span>
                            </div>
                            <div className='right_ticket'>
                                <div className='dotted_border'>
                                    <div>
                                        <div className='tick_number'>{item.pass_id ? item.pass_id : '356789098765'}</div>
                                        <p>Ticket Number</p>
                                    </div>
                                    <div className='valid_text'>Valid until {formatDate(item.created_at)} 23:59 PM</div>
                                    <div className='ticket_logo'>
                                        <img src={khiladiLogo} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }) : <div className='no_tickets zero'>
                    No Tickets
                </div>}
            </>
    )
}

export default TicketComponent