import React, { useEffect } from 'react'
import { useState } from 'react'
import "../../Verfiy.css"
import axios from "axios";
import homeImage from "./verfiyImage/verification.png"
import loader from "./verfiyImage/khiladi_adda_loader.gif"
import OtpInput from 'react-otp-input';
import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import { ReactComponent as Verified } from "./verfiyImage/verified.svg"
import { ReactComponent as Wrong } from "./verfiyImage/wrong.svg"
import { ReactComponent as Pending } from "./verfiyImage/pending.svg"
import { useNavigate } from 'react-router-dom'
import Queries from './Queries';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AadhaarVerify() {
    const [aadhaar, setAadhaar] = useState('');
    const [open, setOpen] = useState(false);
    const [otp, setOtp] = useState('');
    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);
    const [otpBox, setOtpBox] = useState(false);
    const [loading, setLoading] = useState(false)
    const [is_aadhaar, setIs_aadhaar] = useState(false);
    const [clientId, setClientId] = useState("")
    let userDetails = {}
    const userAadhar = JSON.parse(localStorage.getItem("AADHAR_OTP_DETAILS"));
    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const navigate = useNavigate();
    const handleDelete = () => {
        setOpen(false)
    }

    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }

    // user login otp
    const sendOtp = async () => {
        if (aadhaar.length < 12 || !aadhaar.length) {
            handleOpen(0, "Please fill correct Aadhaar number")
        }
        if (aadhaar.length === 12) {
            setLoading(true);

            let aadharObject = {
                "id_number": aadhaar
            }
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + process.env.REACT_APP_AADHAAR_TOKEN,
                    'Content-Type': 'application/json'
                }
            };

            let uri = process.env.REACT_APP_AADHAAR_URL
            let responseBody = await axios.post(uri, aadharObject, axiosConfig);
            let responseObject = responseBody.data;
            if (responseObject.success === true && responseObject.status_code === 200) {

                //aadhaar already exist
                await axiosClient
                    .post("/user/mobile/uan/exists", {
                        number: aadhaar
                    })
                    .then(async (res) => {
                        if (res.data.status === false) {
                            handleOpen(0, "Aadhaar already taken. Please try another Aadhaar Card")
                            setLoading(false)
                        } else if (res.data.status === true) {

                            let generateOtpUri = "https://kyc-api.aadhaarkyc.io/api/v1/aadhaar-v2/generate-otp";
                            let otpResponseBody = await axios.post(generateOtpUri, JSON.stringify(aadharObject), axiosConfig);
                            try {
                                let otpResponseObject = otpResponseBody.data;
                                console.log("otpResponseObject", otpResponseObject)
                                if (otpResponseObject.success === true && otpResponseObject.status_code === 200) {
                                    setLoading(false)
                                    setClientId(otpResponseObject?.data?.client_id)
                                    setOtpBox(true)
                                    handleOpen(1, `OTP has been sent to your registered mobile Number having last digits ${responseObject?.data?.last_digits}`)
                                } else {
                                    setLoading(false)
                                    handleOpen(0, `There is some technical issue while verifying your Aadhaar card please try again in 1 - 2 hours.\nFor any issue please contact us on chat support.\n\n!!Thanks!!`)
                                }
                            } catch (err) {
                                setLoading(false)
                                console.log(err)
                            }

                        }
                    })
                    .catch((err) => console.log(err))

            } else if (!responseObject.success || !responseObject.status_code) {
                setLoading(false);
                handleOpen(0, `There is some technical issue while verifying your Aadhaar card please try again in 1 - 2 hours.\nFor any issue please contact us on chat support.\n\n!!Thanks!!`)

            }
        }

    }
    // user login otp submission
    const submitOtp = async () => {
        if (otp?.length < 6) {
            setOpen(true)
            setDialog({
                icon: 0,
                message: `Please Enter 6 Digit OTP.`
            })
        }
        if (otp?.length === 6) {

            setLoading(true)
            const states = ['Nagaland', 'Meghalaya', 'Sikkim', 'Andhra Pradesh', 'Assam', 'Odisha', 'Telangana', 'Orissa', 'Tamilnadu', 'Tamilnadu ']

            let verifyOtpUri = process.env.REACT_APP_AADHAAR_OTP_VERIFY

            let aadharObject = {
                "client_id": clientId,
                "otp": otp
            }
            let axiosConfig = {
                headers: {
                    Authorization: 'Bearer ' + process.env.REACT_APP_AADHAAR_TOKEN,
                    'Content-Type': 'application/json'
                }
            };
            try {
                let responseBody = await axios.post(verifyOtpUri, aadharObject, axiosConfig);
                let responseObject = responseBody?.data;
                if (responseObject.status_code === 200 && responseObject.success === true) {
                    setLoading(false)
                    userDetails.name = responseObject.data?.full_name
                    userDetails.dob = responseObject?.data?.dob;
                    userDetails.aadhaar_number = responseObject.data?.aadhaar_number;
                    let careOf = responseObject.data.care_of || "";
                    let houseAddress = responseObject.data.address.house || "";
                    let locAddress = responseObject.data.address.loc || "";
                    let streetAddress = responseObject.data.address.street || "";
                    let po = responseObject.data.address.po || "";
                    let vtc = responseObject.data.address.vtc || "";
                    let distt = responseObject.data.address.dist || "";
                    let state = responseObject.data.address.state || "";
                    let country = responseObject.data.address.country || "";
                    let zip = responseObject.data.zip || "";
                    userDetails.address = careOf + ", " + houseAddress + ", " + streetAddress + ", " + locAddress + ", " + po + ", " + vtc + ", " + distt + ", " + state + ", " + country + ", " + zip;
                    userDetails.city = responseObject.data.address.dist || "";
                    userDetails.state = responseObject.data.address.state || "";
                    userDetails.country = responseObject.data.address.country || "";
                    userDetails.zip_code = responseObject.data.zip || "";
                    const ageDifMs = Date.now() - new Date(responseObject.data.dob);
                    const ageDate = new Date(ageDifMs); // miliseconds from epoch
                    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
                    if (age < 18 || states.includes(userDetails.state)) {
                        let message = (age < 18 && !states.includes(userDetails?.state)) ? "Your age is below 18 years and you are not eligible for real money gaming.\nPlease try again with the Aadhar card of a person who is 18 or above 18 years." : (states.includes(userDetails.state) && age > 18) ? `You belong to  ${userDetails.state}  where the game of skills is not allowed so, please retry with another Aadhar where the game of skill is allowed.` : `Your age is below 18 years and you belong to ${userDetails.state}, so you are not eligible to play real money skill-based games. We request you to please retry with the Aadhar card of a person of 18 or above 18 years and with the allowed states.`
                        return handleOpen(0, message)
                    } else {
                        handleOpen(1, "Otp has been verified.")
                        setClientId("")
                        setIs_aadhaar(true);
                        localStorage.setItem("AADHAR_OTP_DETAILS", JSON.stringify(userDetails))
                    }
                } else {
                    setLoading(false)
                    setClientId("")
                    handleOpen(0, "Enter Correct Details")

                }
            } catch (error) {
                setLoading(false)
                handleOpen(0, "Some technincal issue. Please Try agin after some time.")
                console.log(error)
            }
        }

    }

    const resendOtp = async () => {
        setMinutes(5);
        setSeconds(0);
        handleOpen(2, `Sending Otp Again`)

        let aadharObject = {
            "id_number": aadhaar
        }
        let axiosConfig = {
            headers: {
                Authorization: 'Bearer ' + process.env.REACT_APP_AADHAAR_TOKEN,
                'Content-Type': 'application/json'
            }
        };
        let generateOtpUri = process.env.REACT_APP_AADHAAR_OTP
        try {


            let otpResponseBody = await axios.post(generateOtpUri, JSON.stringify(aadharObject), axiosConfig);
            let otpResponseObject = otpResponseBody.data;

            if (otpResponseObject.success === true && otpResponseObject.status_code === 200) {
                console.log("otp sent", otpResponseObject)
                setClientId(otpResponseObject?.data?.client_id)
                setOtpBox(true)
                handleOpen(1, `OTP has been sent to your registered mobile Number`)
            } else {
                handleOpen(0, `There is some technical issue while verifying your Aadhaar card please try again in 1 - 2 hours.\nFor any issue please contact us on chat support.\n\n!!Thanks!!`)
            }
        } catch (error) {
            handleOpen(0, "An error occurred. Please try again later.")
            console.error(error);
        }

    }

    useEffect(() => {
        if (otpBox) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }

    }, [otpBox, seconds]);
    useEffect(() => {
        const preventPullToRefresh = (event) => {
            if (event.touches.length > 1) return;
            event.preventDefault();
        }
        document.addEventListener('touchmove', preventPullToRefresh, { passive: false });
        return () => {
            document.removeEventListener('touchmove', preventPullToRefresh);
        };
    }, []);
    useEffect(() => {
        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
    }, [])
    return (
        <>
            <div className='verfiyMain'>
                <div className='verfiyTop'>
                    <h1>Complete Your AADHAAR KYC</h1>
                </div>
                {loading ?
                    <div className='loaderKaBox'>
                        <img src={loader} alt='loader' className='loaderKA' />
                    </div>
                    : <>

                        <div className='verifyBody'>
                            <div className='homeUImage'>
                                <img src={homeImage} alt="homeImage" />
                            </div>
                            {
                                is_aadhaar ?
                                    <>
                                        <div className='aadhaarDetails'>
                                            <h3>Aadhaar Details</h3>
                                            <div className='basicDetail'>
                                                <p><span>Name : </span> {userAadhar?.name}</p>
                                                <p><span>DOB : </span> {userAadhar?.dob}</p>
                                                <p><span>Address : </span> {userAadhar?.address}</p>
                                            </div>
                                            <div className='verfiysubmitCover'>
                                                <p className='verifysubmit' onClick={() => navigate("/pan-verfiy")}>Proceed to PAN verify</p>
                                            </div>
                                        </div>

                                    </> :
                                    <>
                                        <div className='inputDetails'>
                                            <p className='para'>Aadhar Number</p>


                                            <div className='inputCover'>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    disabled={otpBox}
                                                    className="profileInput"
                                                    value={aadhaar}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 12) {
                                                            setAadhaar(e?.target?.value)
                                                        }
                                                    }}
                                                />
                                                {
                                                    !otpBox ? <p className='otp' onClick={() => sendOtp()}>Get OTP</p> : null
                                                }

                                            </div>
                                        </div>
                                        {
                                            otpBox ? <>
                                                <div className='inputDetails'>
                                                    <p className='para'>Enter the 6 digit OTP</p>
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        inputType="number"
                                                        inputStyle="otpInput"
                                                        renderSeparator={<span>-</span>}
                                                        renderInput={(props) => <input {...props}

                                                        />}
                                                    />
                                                </div>
                                                <div className="countdown-text">
                                                    {seconds > 0 || minutes > 0 ? (
                                                        <p>
                                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) : (
                                                        <p>Didn't recieve code?</p>
                                                    )}



                                                    <div

                                                        onClick={() => {
                                                            if (seconds > 0 || minutes > 0) {
                                                                return
                                                            } else {
                                                                resendOtp();
                                                            }
                                                        }}
                                                        className={seconds > 0 || minutes > 0 ? 'resendButton' : 'resendButton active'}
                                                    >  <p>
                                                            Resend OTP
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='verfiysubmitCover'>
                                                    <h3 className='verifysubmit' onClick={() => submitOtp()}>Submit</h3>
                                                </div>
                                            </> : null
                                        }</>



                            }
                        </div>
                        <Queries />
                    </>}
            </div>

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>
                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='okay' onClick={() => handleDelete()}>Okay</div>
                    </div>
                </div>
            </Dialog >

        </>
    )
}

export default AadhaarVerify