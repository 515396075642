import React, { useEffect, useState } from 'react'
import axiosClient from '../../axios';

import TicketComponent from './TicketComponent'
import loader from "../Verification/verfiyImage/khiladi_adda_loader.gif"
import "../NewOffer-UserPass/userpass.css"

function CheckWinner() {

    const [WinnerTickets, setWinnerTickets] = useState([])
    const [Loading, setLoading] = useState(false)
    const jwt = JSON.parse(localStorage.getItem("JWT"));

    useEffect(() => {
        fetchWinnerTickets()
    }, [])


    function getYesterdayDate() {
        // Create a new Date object for today
        let today = new Date();
    
        // Subtract one day (in milliseconds)
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
    
        // Define an array of month names
        const months = [
            "January", "February", "March", "April", "May", "June", 
            "July", "August", "September", "October", "November", "December"
        ];
    
        // Get the day, month, and year
        let day = yesterday.getDate();
        let month = months[yesterday.getMonth()];
        let year = yesterday.getFullYear();
    
        // Return the formatted date string
        return `${day} ${month} ${year}`;
    }
    

    const fetchWinnerTickets = async () => {
        setLoading(true);
        let head = process.env.REACT_APP_ADMIN_URL || "https://apiv3.admin.khiladiadda.com/api/"
        let url = head + `admin/get-winner-passes`
        // let obj = {}
        // obj['user_id'] = localStorage.getItem('_id')
        axiosClient
            .get(url)
            .then((res) => {
                const data = res.data?.response;
                setWinnerTickets(data)
                console.log(res.data)
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
            });

    }

    if (Loading) {
        // return <h1 className='loading_text'>Loading.....</h1>
        return <div className='loaderKaBox'>
            <img src={loader} alt='loader' className='loaderKA' />
        </div>
    }

    return (
        <>
            <div className='alltickets'>{`Winner Ticket of ${getYesterdayDate()}`}</div>
            <div className='ticket_div' style={{height : '90vh'}}>
                <TicketComponent Tickets={WinnerTickets} />
            </div>
        </>

    )
}

export default CheckWinner