import axios from "axios";

// const jwt = JSON.parse(localStorage.getItem("JWT"));
const axiosClient = axios.create();

axiosClient.defaults.baseURL = "https://prod.api.pqmvhqqrwizpghjfbhsaozediqdecnmwkwrthl.k-adda.com/";
// axiosClient.defaults.baseURL = "https://uat.api.khiladiadda.com/";
axiosClient.defaults.headers = {
    app_version: "2.1.4",
    key_enc_: process.env.REACT_APP_KEY_ENC,
    secure_pass_key_: process.env.REACT_APP_SECURE_KEY,
    'x-lru-yek': process.env.REACT_APP_LRU_KEY
};

axiosClient.interceptors.response.use((config) => {

    let { data: { message, statusCode } } = config
    if (statusCode === 500) {
        console.log("message", message)
        // alert(message)
        localStorage.clear()
        setTimeout(
            () => window.location.replace("/login"),
            4500
        );
    }
    return config;
}, (error) => {
    let { response: { data: { statusCode } } } = error

    if (statusCode === 500) {
        console.log("message", error)
        localStorage.clear()
        setTimeout(
            () => window.location.replace("/login"), 4500
        );
    }
    return Promise.reject(error);
});

export default axiosClient;
