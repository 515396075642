import React from 'react'
import ReactPlayer from 'react-player/youtube'


function Rules() {
    return (
        <div>

            <div className='youtubeVideo'>
                <ReactPlayer url="https://youtu.be/-sCuy3jqUEU" className="profilePlay" />
            </div>


            <div className='notice'>
                <p>
                    Rules and Regulations
                </p>

                <ol>
                    <li>Khiladi Adda Daily Contest (14th-20th August 2024) will be based on the criteria of tickets.</li>
                    <li>You need to buy tickets for any particular day of the contest.</li>
                    <li>Rules for buying contest tickets : </li>

                    <ul className='list-style'>
                        <li>Add INR 500 or more to buy one ticket. (Example 1: If you add INR 501, you will get one ticket for the contest; Example 2: If you add INR 999, you will get one ticket.)</li>
                        <li>If you deposit ₹499 and later deposit ₹1, it won’t be eligible for a ticket.</li>
                        <li>You will also not get a ticket if you deposit, for example, ₹100 five times. The amount has to be ₹500 in one deposit.</li>
                        <li>Each ticket will be valid until 11:59 PM of the buying date (for example, if you added INR500 on 1st August 2024, ticket received will be valid until 11:59 PM 1st August 2024)</li>
                        <li>You can also get a ticket by transferring a minimum of ₹500 from your winnings balance to the deposit balance.</li>
                    </ul>

                    <li>If you want to participate in the contest the next day, you need to buy tickets for that day again. You can’t use the past-day tickets.</li>
                    <li>At the end of each day, a winner will be announced for that day, and the prize will be given accordingly.</li>
                    <li>The prize will be allotted on a random basis by selecting a particular ticket from the tickets of all users.</li>
                    <li>You can increase your chances of winning the contest by having more tickets.</li>
                    <li>The more deposits you make in ₹500 amounts, the more tickets you get and thus more chances of winning.</li>
                    <li>The number of tickets bought on any day will be valid for that day only.</li>
                    <li>You can check the number of tickets you have on the dedicated web page.</li>
                    <li>You can also check the winners of any past day.</li>
                    <li>The winners will be decided each day and also announced on Khiladi Adda’s social media, so make sure you follow Khiladi Adda. Khiladi Adda’s Instagram link is given below: <br></br>
                        <a href="https://www.instagram.com/khiladiadda">https://www.instagram.com/khiladiadda</a>
                    </li>
                    <li>The gifts will be given after the contest ends.</li>
                    <li>Once the prize is given, it will NOT be exchanged for any other gifts or coins.</li>
                    <li>The prize for each day exceeds up to ₹15,000.</li>
                </ol>

            </div>
        </div>
    )
}

export default Rules