import React, { useEffect } from 'react'
import { useState } from 'react'
import "../../Verfiy.css"
import loader from "./verfiyImage/khiladi_adda_loader.gif"
import homeImage from "./verfiyImage/homeImage.png"
import adharVerify from "./verfiyImage/adharVerify.png"
import OtpInput from 'react-otp-input';
import axiosClient from '../../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Verified } from "./verfiyImage/verified.svg"
import { ReactComponent as Wrong } from "./verfiyImage/wrong.svg"
import { ReactComponent as Pending } from "./verfiyImage/pending.svg"
import { useNavigate } from 'react-router-dom'
import Queries from './Queries'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Verfiy() {
    const [number, setNumber] = useState();
    const [otp, setOtp] = useState('');
    const [open, setOpen] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [loading, setLoading] = useState(false)

    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const [is_aadhaar, setIs_aadhaar] = useState(false);
    const [is_aadhaarDetails, setIs_aadhaarDetails] = useState(false);
    const [is_panDetails, setIs_panDetails] = useState(false)
    const [response, setResponse] = useState({});
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);
    const [incorrectCount, setIncorrectCount] = useState(0)
    const navigate = useNavigate();
    const handleDelete = () => {
        setOpen(false)
    }

    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }

    // user login otp
    const sendOtp = () => {
        setLoading(true);
        console.log(number)
        if (!number || number.length < 10 || !number.length) {
            setLoading(false);
            handleOpen(0, "Please fill correct number.")
        }
        else if (number.length === 10) {
            const data = {
                mobile: number
            }
            axiosClient
                .post(`/user/mobile/send_otp`, data)
                .then((res) => {
                    setLoading(false);
                    if (res.data.status === false) {
                        handleOpen(0, res.data.message)
                    }
                    else if (res.data.status === true) {
                        setOtpBox(true);
                        handleOpen(1, res.data.message)
                    }
                })
                .catch((err) => setLoading(false));
        }
        else {
            setLoading(false);
            handleOpen(0, "Please fill correct number.")
        }
    }
    // user login otp submission
    const submitOtp = async () => {
        setLoading(true);
        if (otp?.length < 6) {
            setLoading(false);
            setOpen(true)
            setDialog({
                icon: 0,
                message: `Please Enter 6 Digit OTP.`
            })
        }
        if (otp?.length === 6) {
            const data = { mobile: number, otp: otp }
            await axiosClient
                .post(`/user/mobile/verify_otp`, data)
                .then((res) => {
                    setLoading(false);
                    if (res?.response?.status === 500) {
                        handleOpen(0, "Please enter correct OTP.")
                        setIncorrectCount(incorrectCount + 1)
                    } else if (res?.data?.status === true) {
                        handleOpen(1, "Otp has been verified.")
                        setIs_aadhaar(true)
                        localStorage.setItem("MOBILE", JSON.stringify(number))
                        if (res?.data?.is_aadhar_verified === true) {
                            localStorage.setItem("AADHAR_OTP_DETAILS", JSON.stringify(res?.data?.response))
                            setIs_aadhaarDetails(true)
                            setResponse(res?.data?.response)
                        }
                        if (res?.data?.is_pan_verified === true) {
                            setIs_panDetails(true)
                            setTimeout(
                                () => handleOpen(1, `Your KYC has been completed.\n Our team will contact you within 24 hours via phone or WhatsApp to guide you through the TDS certification process.`),
                                1000
                            );
                        }
                    }
                    else {
                        setIncorrectCount(incorrectCount + 1)
                        handleOpen(0, "Otp Verification Failed.Please try again later")
                    }
                })
                .catch((err) => {
                    setIncorrectCount(incorrectCount + 1)
                    setLoading(false);
                    handleOpen(0, "Otp Verification Failed.Please try again later")
                });
        }
    }
    const resendOtp = async () => {
        setLoading(true);
        setMinutes(0);
        setSeconds(0);
        const data = {
            mobile: number
        }
        handleOpen(2, `Sending Otp Again`)
        await axiosClient
            .post(`/user/mobile/send_otp`, data)
            .then((res) => {
                setLoading(false)
                if (res.data.status === false) {
                    handleOpen(0, res.data.message)
                }
                else if (res.data.status === true) {
                    console.log(res)
                    setOtpBox(true);
                    handleOpen(1, res.data.message)
                }
            })
            .catch((err) => setLoading(false));

    }

    useEffect(() => {
        if (otpBox) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }

    }, [otpBox, seconds]);
    useEffect(() => {
        window.location.hash = "no-back-button";
        window.location.hash = "Again-No-back-button";
        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
    }, [])
    useEffect(() => {
        if (incorrectCount > 3) {
            setIncorrectCount(0)
            setMinutes(0);
            setSeconds(0)
            handleOpen(0, "Otp limit exceeded. Please resend otp again.")
        }
    }, [incorrectCount])
    return (
        <>
            <div className='verfiyMain'>
                <div className='verfiyTop'>
                    <h1>{is_aadhaarDetails && is_panDetails ? 'KYC COMPLETED' : `Complete Your PAN KYC`}</h1>
                </div>
                {loading ?
                    <div className='loaderKaBox'>
                        <img src={loader} alt='loader' className='loaderKA' />
                    </div>
                    : <>
                        <div className='verifyBody'>
                            {is_aadhaarDetails && is_panDetails ? <></> :
                                <div className='homeUImage'>
                                    <img src={is_aadhaar ? adharVerify : homeImage} alt="homeImage" />
                                </div>}

                            {
                                is_aadhaar ?
                                    <>
                                        {
                                            is_aadhaarDetails ? (
                                                <div className='aadhaarDetails'>
                                                    <h3>Aadhaar Details</h3>
                                                    <div className='basicDetail'>
                                                        <p><span>Name : </span> {response?.name}</p>
                                                        <p><span>DOB : </span> {response?.dob}</p>
                                                        <p><span>City : </span> {response?.city}</p>
                                                        <p><span>State : </span> {response?.state}</p>
                                                        <p><span>Country : </span> {response?.country}</p>
                                                        <p><span>Zip Code : </span> {response?.zip_code}</p>
                                                        <p><span>Mobile Number : </span> {number}</p>
                                                    </div>
                                                    {is_panDetails ? <>
                                                        <h3>PAN Details</h3>
                                                        <div className='basicDetail'>
                                                            <p><span>PAN : </span> {response?.pan_info?.number}</p>
                                                            <p><span>Name : </span> {response?.name}</p>
                                                        </div>
                                                        <br />
                                                        <p className='suBtext'>Your KYC has been completed. Our team will contact you within 24 hours via phone or WhatsApp to guide you through the TDS certification process.</p>
                                                        <p className='suBtext'>आपका KYC पूरा हो गया है। हमारी टीम आपको 24 घंटे के भीतर फोन या व्हाट्सएप के जरिए संपर्क करेगी और आपको टीडीएस सर्टिफिकेशन प्रक्रिया के बारे में सहायता प्रदान करेगी।।</p>
                                                    </> :
                                                        <>
                                                            <div className='verfiysubmitCover'>
                                                                <p className='verifysubmit'
                                                                    onClick={() => navigate("/pan-verfiy")}>
                                                                    Proceed to PAN KYC</p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            ) : (
                                                <div className='aadhaarDetails'>
                                                    <h3>Aadhaar Details</h3>
                                                    <div className='basicDetail'>
                                                        <p><span>Name : </span> Not Found</p>

                                                    </div>
                                                    <div className='verfiysubmitCover'>
                                                        <p className='verifysubmit' onClick={() => navigate("/aadhar-verfiy")}>Proceed to AADHAAR verify</p>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </> :
                                    <>
                                        <div className='inputDetails'>
                                            <p className='para'>Mobile Number</p>
                                            <div className='inputCover'>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    className="profileInput"
                                                    value={number}
                                                    placeholder={"Enter your Mobile Number"}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 10) {
                                                            setNumber(e.target.value)
                                                        }
                                                    }}
                                                />
                                                {
                                                    !otpBox ? <p className='otp' onClick={() => sendOtp()}>Get OTP</p> : null
                                                }
                                            </div>
                                        </div>
                                        {
                                            otpBox ? <>
                                                <div className='inputDetails'>
                                                    <p className='para'>Enter the 6 digit OTP</p>
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        inputType="number"
                                                        inputStyle="otpInput"
                                                        renderSeparator={<span>-</span>}
                                                        renderInput={(props) => <input {...props}
                                                        />
                                                        }
                                                    />
                                                </div>
                                                <div className="countdown-text">
                                                    {seconds > 0 || minutes > 0 ? (
                                                        <p>
                                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) : (
                                                        <p>Didn't recieve code?</p>
                                                    )}


                                                    <div

                                                        onClick={() => {
                                                            if (seconds > 0 || minutes > 0) {
                                                                return
                                                            } else {
                                                                resendOtp();
                                                            }
                                                        }}
                                                        className={seconds > 0 || minutes > 0 ? 'resendButton' : 'resendButton active'}
                                                    >  <p>
                                                            Resend OTP
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='verfiysubmitCover'>
                                                    <h3 className='verifysubmit' onClick={() => submitOtp()}>Submit</h3>
                                                </div>
                                            </> : null
                                        }
                                    </>
                            }



                        </div>
                    </>}
                <Queries />
            </div>

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>

                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='okay' onClick={() => handleDelete()}>Okay</div>
                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default Verfiy