
import React, { useEffect } from 'react'
import { useState } from 'react'
import "./Verfiy.css"
import loader from "./screens/Verification/verfiyImage/khiladi_adda_loader.gif"
import homeImage from "./screens/Verification/verfiyImage/homeImage.png"
import OtpInput from 'react-otp-input';
import axiosClient from './axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Verified } from "./screens/Verification/verfiyImage/verified.svg"
import { ReactComponent as Wrong } from "./screens/Verification/verfiyImage/wrong.svg"
import { ReactComponent as Pending } from "./screens/Verification/verfiyImage/pending.svg"
import axios from 'axios'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AccountDelete() {
    const [number, setNumber] = useState();
    const [otp, setOtp] = useState('');
    const [open, setOpen] = useState(false);
    const [deleteBox, setDeleteBox] = useState(false);
    const [otpBox, setOtpBox] = useState(false);
    const [loading, setLoading] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const [dialog, setDialog] = useState({
        icon: 0,
        message: "Under Process"
    })
    const [is_aadhaar, setIs_aadhaar] = useState(false);
    const jwt = JSON.parse(localStorage.getItem("JWT"));

    const [response, setResponse] = useState({});
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(30);
    const [jwts, setJwts] = useState(jwt)
    const [incorrectCount, setIncorrectCount] = useState(0)
    const handleDelete = () => {
        setOpen(false)
    }

    const handleOpen = (i, m) => {
        setOpen(true);
        setDialog({
            icon: i,
            message: m
        })
    }
    const handleDeleteUser = async () => {
        setLoading(true);
        setDeleteBox(false);
        setDeletePopup(true);
        const apiResp = await axios.post(`https://apiv3.admin.khiladiadda.com/api/admin/block_request_user_id`, { user_id: response?.user_id })
        let responseObject = apiResp.data;

        await new Promise((resolve) => setTimeout(resolve, 3000));
        if (responseObject?.status === true) {
            setDialog({
                icon: 1,
                message: "Your account has been approved."
            })
        } else {
            setDialog({
                icon: 0,
                message: responseObject?.message
            })
        }
        setLoading(false);
        setOpen(true);

    }

    const getProfile = async () => {
        try {
            const response = await axiosClient.get("/profile?transaction=false", {
                headers: {
                    'x-access-token': jwts,
                    'token': jwts
                }
            });
            if (response.data.status === false) {
                localStorage.clear();
                window.location.replace("/login")
                return
            }
            if (response.data.status === true) {
                // let bonus = (response.data.response.coins_converted.n_times == 0) ? 9 : (response.data.response.coins_converted.n_times == 1) ? 7 : 5;
                let bonus = response.data?.convertPercentage;
                // let bonus = 5;
                let datas = response.data.response;
                let saves = {
                    user_id: datas._id,
                    name: datas.name,
                    dob: datas.dob,
                    city: datas.city,
                    state: datas.state,
                    country: datas.country,
                    zip_code: datas.zip_code,
                    mobile: datas.mobile,
                    info: datas.coins,
                    winnig: response.data?.winningAmount,
                    coins_converted: bonus

                };
                setResponse(saves);
                localStorage.setItem("data", JSON.stringify(saves));
                return saves; // Return the saves object
            }
        } catch (error) {
            console.error("Error in getProfile:", error);
            throw error;
        }
    }

    // user login otp
    const sendOtp = () => {
        setLoading(true);
        if (!number || number.length === 0 || Number(number.slice(0, 1)) < Number(6)) {
            setLoading(false);
            handleOpen(0, "Enter your Mobile Number")
        }
        else if (number.length < 10 || !number.length) {
            setLoading(false);
            handleOpen(0, "Enter your 10 digit Mobile Number")
        }
        else if (number.length === 10) {
            const data = {
                mobile: number
            }
            axiosClient
                .post(`/auth/login`, data)
                .then((res) => {
                    setLoading(false);
                    console.log("res", res.data)
                    if (res.data.status == false) {
                        handleOpen(0, res.data.message)
                    }
                    else if (res.data.status === true) {
                        setOtpBox(true);
                        handleOpen(1, "OTP has been successfully send to your number.")
                    }
                })
                .catch((err) => setLoading(false));
        }
        else {
            setLoading(false);
            handleOpen(0, "Please fill correct number.")
        }
    }
    // user login otp submission
    const submitOtp = async () => {
        setLoading(true);
        if (otp?.length < 6) {
            setLoading(false);
            setOpen(true)
            setDialog({
                icon: 0,
                message: `Please Enter 6 Digit OTP.`
            })
            return
        }
        if (otp?.length === 6) {
            const data = { mobile: number, otp: otp }
            await axiosClient.post(`/auth/website/verify_password_otp/login`, data)
                .then((res) => {
                    setLoading(false);
                    if (res?.data?.status === true) {
                        setJwts(res.data.jwt)
                        try {
                            axiosClient.get("/profile?transaction=false", {
                                headers: {
                                    'x-access-token': res.data.jwt,

                                }
                            }).then((respon) => {
                                if (respon.data.status == true) {
                                    handleOpen(1, "Otp has been verified.")
                                    setIs_aadhaar(true)
                                    localStorage.setItem("MOBILE", JSON.stringify(number))
                                    localStorage.setItem("JWT", JSON.stringify(res.data.jwt))
                                    // let bonus2 = (respon.data.response.coins_converted.n_times == 0) ? 9 : (respon.data.response.coins_converted.n_times == 1) ? 7 : 5;
                                    let bonus2 = respon.data.convertPercentage
                                    // let bonus2 = 5

                                    let datas = respon.data.response
                                    let saves = {
                                        user_id: datas._id,
                                        name: datas.name,
                                        dob: datas.dob,
                                        city: datas.city,
                                        state: datas.state,
                                        country: datas.country,
                                        zip_code: datas.zip_code,
                                        mobile: datas.mobile,
                                        info: datas.coins,
                                        winnig: respon.data?.winningAmount,
                                        coins_converted: bonus2
                                    }
                                    setResponse(saves)
                                    console.log("saves", saves)
                                    localStorage.setItem("data", JSON.stringify(saves))
                                }
                            })
                        } catch (error) {
                            console.log("error", error)
                            handleOpen(0, "Some Technicnal Issue")
                        }

                    }
                    else {
                        setIncorrectCount(incorrectCount + 1)
                        handleOpen(0, res.data.message)
                    }
                })
                .catch((err) => {
                    setIncorrectCount(incorrectCount + 1)
                    setLoading(false);
                    handleOpen(0, "Otp Verification Failed.Please try again later")
                });
        }
    }
    const resendOtp = async () => {
        setLoading(true);
        setMinutes(0);
        setSeconds(0);
        const data = {
            mobile: number
        }
        handleOpen(2, `Sending Otp Again`)
        await axiosClient
            .post(`/auth/login`, data)
            .then((res) => {
                setLoading(false)
                if (res.data.status === false) {
                    handleOpen(0, res.data.message)
                }
                else if (res.data.status === true) {
                    setOtpBox(true);
                    handleOpen(1, res.data.message)
                }
            })
            .catch((err) => setLoading(false));

    }

    useEffect(() => {
        if (otpBox) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }

    }, [otpBox, seconds]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (jwts?.length > 10 || jwt) {
            setIs_aadhaar(true)
            getProfile()
            return
        }
    }, [])

    return (
        <>
            <div className='verfiyMain'>
                <div className='verfiyTop'>
                    <h1>Khiladi Adda</h1>
                </div>
                {loading ?
                    <div className='loaderKaBox'>
                        <img src={loader} alt='loader' className='loaderKA' />
                    </div>
                    : <>
                        <div className='verifyBody'>
                            <div className='newNote'>
                                <h3>Account Deletion Request</h3>
                            </div>



                            {is_aadhaar ? "" :
                                <div className='homeUImage'>
                                    <img src={homeImage} alt="homeImage" />
                                </div>}

                            {
                                is_aadhaar ?
                                    <>
                                        {
                                            <div className='aadhaarDetails'>
                                                <h3>User Details</h3>
                                                <div className='basicDetail'>
                                                    <p className='detailBox'><span>Name : </span> {response?.name}</p>
                                                    <p className='detailBox'><span>Mobile Number : </span> {response.mobile}</p>
                                                    <p className='para'>Wallet Details</p>
                                                    <div className='detailFlexW'>
                                                        <div className='borderWhite'>
                                                            <p>Deposit</p>
                                                            <p>{Number(response?.info?.deposit).toFixed(2)}</p>
                                                        </div>
                                                        <div className='borderWhite'>
                                                            <p>Winning</p>
                                                            <p>{Number(response?.info?.winning).toFixed(2)}</p>
                                                        </div>
                                                        <div className='borderWhite'>
                                                            <p>Bonus</p>
                                                            <p>{Number(response?.info?.bonus).toFixed(2)}</p>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className='verfiysubmitCover'>
                                                    <p className='verifysubmit' onClick={() => setDeleteBox(true)}>Delete Account</p>
                                                </div>
                                            </div>
                                        }

                                    </> :
                                    <>
                                        <div className='inputDetails'>
                                            <p className='para'>Mobile Number</p>
                                            <div className='inputCover'>
                                                <input
                                                    id="name"
                                                    type="number"
                                                    className="profileInput"
                                                    value={number}
                                                    placeholder={"Enter your Mobile Number"}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 10) {
                                                            setNumber(e.target.value)
                                                        }
                                                    }}
                                                />
                                                {
                                                    !otpBox ? <p className='otp' onClick={() => sendOtp()}>Get OTP</p> : null
                                                }

                                            </div>


                                        </div>

                                        {
                                            otpBox ? <>
                                                <div className='inputDetails'>
                                                    <p className='para'>Enter the 6 digit OTP</p>
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        numInputs={6}
                                                        inputType="number"
                                                        inputStyle="otpInput"
                                                        renderSeparator={<span>-</span>}
                                                        renderInput={(props) => <input {...props}
                                                        />
                                                        }
                                                    />
                                                </div>
                                                <div className="countdown-text">
                                                    {seconds > 0 || minutes > 0 ? (
                                                        <p>
                                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                            {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) : (
                                                        <p>Didn't recieve code?</p>
                                                    )}


                                                    <div

                                                        onClick={() => {
                                                            if (seconds > 0 || minutes > 0) {
                                                                return
                                                            } else {
                                                                resendOtp();
                                                            }
                                                        }}
                                                        className={seconds > 0 || minutes > 0 ? 'resendButton' : 'resendButton active'}
                                                    >  <p>
                                                            Resend OTP
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='verfiysubmitCover'>
                                                    <h3 className='verifysubmit' onClick={() => submitOtp()}>Submit</h3>
                                                </div>
                                            </> : <>
                                                <div className='newNote'>
                                                    <h3>Important Note</h3>
                                                    <ol className='newNoteOl'>
                                                        <li>Your all data will be removed from our database and you won’t be able to login again.</li>
                                                        <li>Withdraw all the amount available in your wallet before deleting your account.</li>
                                                        <li>Your all the gaming history, stats, points will be lost.</li>
                                                        <li>Your account will be deleted after 15 days of account deletion request.</li>
                                                    </ol>
                                                    <br />
                                                    {/* <h3>जरुरी सूचना</h3>
                                                    <p>सभी खिलाड़ियों, हमने अपनी कैशबैक नियम को अपडेट किया है जो इस प्रकार हैं:</p>
                                                    <ol className='newNoteOl'>
                                                        <li>दिन के पहले ट्रांजैक्शन पर 9% कैशबैक दिया जाएगा।</li>
                                                        <li>दिन के दूसरे ट्रांजैक्शन पर 7% कैशबैक दिया जाएगा।</li>
                                                        <li>दिन के बचे हुए ट्रांजैक्शन पर 5% कैशबैक दिया जाएगा।</li>
                                                        <li>आप एक बार में 5 लाख रुपये तक ट्रांसफर कर सकते हैं।</li>
                                                        <li>आप दिन में 5 बार पैसे ट्रांसफर कर सकते हैं।</li>

                                                    </ol> */}
                                                </div></>
                                        }
                                    </>
                            }



                        </div>
                    </>}
            </div>
            <Dialog
                open={deleteBox}
                onClose={() => setDeleteBox(false)}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>

                        <div className='iconsImage'>
                            <Pending />
                        </div>
                        <div className='messageBox'>
                            <p>Are You sure you want to delete the account?</p>
                        </div>
                        <div className='yesnobox'>
                            <div className='okay' onClick={() => setDeleteBox(false)}>No</div>
                            <div className='okay' onClick={() => handleDeleteUser()}>Yes</div>
                        </div>
                    </div>
                </div>
            </Dialog >
            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='dialogBox'>
                    <div className='dialogCover'>

                        <div className='iconsImage'>
                            {
                                dialog?.icon === 0 ? <Wrong /> : dialog?.icon === 1 ? <Verified /> : <Pending />
                            }
                        </div>
                        <div className='messageBox'>
                            <p>{dialog?.message}</p>
                        </div>
                        <div className='okay' onClick={() => {
                            handleDelete();
                            if (deletePopup) {
                                localStorage.clear();
                                window.location.reload()
                            }

                        }}>Okay</div>
                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default AccountDelete